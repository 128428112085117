// ** SelectArbetsgivarePage **

import { UserTypes } from 'App.types'
import { TextsObject } from 'Utils/Hooks/useTexts'

const texts: TextsObject = {
  hjalpAttHittaOrgnummer: 'Hjälp att hitta organisationsnummer?',
  labelForInputField: 'Organisationsnummer',
  websiteLink: 'allabolag.se',
  websiteInfo: 'För de som vill veta mer om Sveriges bolag',
  companyFetchError: 'Ett fel uppstod när vi hämtade företag, var vänlig försök igen',
  orgNumSyntaxError: 'Fel format på organisationsnumret',
  a11yErrorMessageOrgNrIncorrectFormat: 'Fel format på organisationsnumret. Ange 10 siffror och ett skiljetecken efter de första 6 siffrorna',
  header: {
    [UserTypes.tjansteman]: 'Är din arbetsgivare ansluten till TRR?',
    [UserTypes.arbetsgivare]: 'Vilket företag representerar du?',
  },
}

export default texts
