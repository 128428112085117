// ** LoginPage **

import { TextsObject } from 'Utils/Hooks/useTexts'

const texts: TextsObject = {
  loginAtTrr: 'Privatperson',
  loginAsEmployer: 'Företagsrepresentant',
  saBehandlarVIDinaPersonuppgifter: 'Så behandlar vi dina personuppgifter',
  howToLogin: 'Hur vill du logga in?',
  bThis: 'BankID på den här enheten',
  bOther: 'BankID på annan enhet',
  freja: 'Freja eID+',
  username: 'Användarnamn',
  password: 'Användarnamn & lösenord',
  otherAlternatives: 'Andra alternativ',
  login: 'Välj hur du vill logga in',
}

export default texts
