import { FormControlLabel, Radio } from '@mui/material'

interface RadioButtonProps {
  checked: boolean
  label: string
  testId?: string
  onClick: () => void
}

const RadioButton = ({ checked, label, testId, onClick }: RadioButtonProps) => {
  return (
    <FormControlLabel
      role="radio"
      aria-checked={checked}
      data-testid={testId}
      checked={checked}
      control={<Radio />}
      label={label}
      onClick={onClick}
    />
  )
}

export default RadioButton
