import { useEffect } from 'react'
import getConfig from 'Utils/Helpers/getConfig'
import { userCreationFinished } from '@trr/app-shell-communication'

import useFetch from '../useFetch'
import useUser from '../useUser'

export interface UsePostArbetsgivarinfo {
  addArbetsgivarinfoIdToUser: () => void
  addArbetsgivarinfoIsSuccess: boolean
  addArbetsgivarinfoIsLoading: boolean
  addArbetsgivarinfoError: Error
}

const usePostArbetsgivarinfo = (): UsePostArbetsgivarinfo => {
  const {
    isSuccess: addArbetsgivarinfoIsSuccess,
    isLoading: addArbetsgivarinfoIsLoading,
    error: addArbetsgivarinfoError,
    performFetch,
  } = useFetch()
  const { linkIdFromUrl } = useUser()

  const addArbetsgivarinfoIdToUser = (): void => {
    performFetch(`${getConfig().PUBLIC_WEB_API_URL}/user/arbetsgivarinfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: linkIdFromUrl,
      }),
    })
  }

  useEffect(() => {
    if (addArbetsgivarinfoIsSuccess) {
      // this just signals to appshell that we are done and need a redirect. It has nothing to do with finishing creation of user.
      userCreationFinished(true)
    }
  }, [addArbetsgivarinfoIsSuccess])

  return {
    addArbetsgivarinfoIsSuccess,
    addArbetsgivarinfoIsLoading,
    addArbetsgivarinfoError,
    addArbetsgivarinfoIdToUser,
  }
}

export default usePostArbetsgivarinfo
