import { ButtonRow, CreatingAccountLoader, PageTitle } from 'Components'
import usePages from 'Utils/Hooks/usePages'
import useCreateUser from 'Utils/Hooks/useCreateUser'
import { Typography, Button } from '@mui/material'

import BasePage from '../BasePage'
import { useGetCompanyQueryState } from 'State/Api/Company'
import { useSliceDispatch, useSliceStateSelector } from 'State/Slices'

const ArbetsgivarSelectedPage = () => {
  const { goToPrevPage } = usePages()
  const triggerDispatch = useSliceDispatch()
  const { organizationNumber, workSituation } = useSliceStateSelector(
    (state) => state.slices.inputs
  )
  const { data: company, isSuccess: isCompanySuccess } =
    useGetCompanyQueryState({
      organizationNumber,
      workSituation,
    })

  const {
    createUser,
    isPosting: isPostingUser,
    isSuccess: isUserCreated,
  } = useCreateUser()

  const showLoading = !isCompanySuccess || isPostingUser || isUserCreated

  const handleGoBack = () => {
    triggerDispatch('setOrganizationNumber', undefined)
    triggerDispatch('setWorkSituation', undefined)
    goToPrevPage()
  }

  const OrganizationNotFound = () => {
    return (
      <BasePage>
        <div data-testid="OrganizationNotFound">
          <PageTitle>Företaget är tyvärr inte anslutet till TRR</PageTitle>
          <Typography variant={'body2'} mb={5}>
            För att kunna ta del av våra tjänster behöver ditt företag vara
            anslutet till oss. Gör en ny sökning om du angett fel
            organisationsnummer.
          </Typography>

          <Typography variant={'body2'} mb={5}>
            Om du avbryter inloggningen tas dina inloggningsuppgifter bort från
            vårt system i enlighet med GDPR.
          </Typography>

          <Button
            data-testid="goBackButton"
            onClick={handleGoBack}
            variant="outlined"
          >
            Gör en ny sökning
          </Button>
        </div>
      </BasePage>
    )
  }

  const OrganizationFound = () => (
    <BasePage>
      <div data-testid="organizationFound">
        <PageTitle>Toppen, det här företaget är anslutet till TRR</PageTitle>
        <Typography variant="body2" mb={5}>
          Kontrollera att uppgifterna ovanför stämmer innan du fortsätter.
        </Typography>
        <ButtonRow marginBottom={9}>
          <Button
            sx={{ marginX: 1 }}
            variant="contained"
            data-testid="forward-button"
            onClick={createUser}
          >
            Fortsätt
          </Button>
          <Button
            sx={{ marginX: 1 }}
            variant="outlined"
            data-testid="goBackButton"
            onClick={handleGoBack}
          >
            Ange annat företag
          </Button>
        </ButtonRow>
      </div>
    </BasePage>
  )

  const OrganizationViewToRender = () => {
    if (company) {
      return <OrganizationFound />
    } else {
      return <OrganizationNotFound />
    }
  }

  return (
    <>
      {showLoading ? (
        <CreatingAccountLoader testSelector={'LoadingElement'} />
      ) : (
        <OrganizationViewToRender />
      )}
    </>
  )
}

export default ArbetsgivarSelectedPage
