import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import getConfig from 'Utils/Helpers/getConfig'

import useFetch from '../useFetch'

interface CompanyResponseItem {
  name: string
  id: string
}

interface CompanyResponse {
  results: CompanyResponseItem[]
}

export interface Company extends CompanyResponseItem {
  orgNumber: string
}

export interface CompanyContextValues {
  setOrgNumber: (orgNumber: string) => void
  setWorkSituation: (workSituation: string) => void
  company: Company
  isLoading: boolean
  error: Error
  isSuccess: boolean
  resetState: () => void
}

interface UseCompanyProps {
  fetchCompany: (orgNumber: string, workSituation: string) => void
  resetCompany: () => void
  company: Company
  isLoading: boolean
  error: Error
  isSuccess: boolean
}

export const CompanyContext =
  React.createContext<CompanyContextValues>(undefined)

export const buildQuery = (
  orgNumber: string,
  workSituation: string = undefined
) => {
  const publicWebUrl = getConfig().PUBLIC_WEB_API_URL
  let query = `${publicWebUrl}/workplace/search/membership-by-org-number?query=${orgNumber}`

  if (workSituation) {
    query += `&worksituation=${workSituation}`
  }
  return query
}

export const CompanyProvider = ({ children }: PropsWithChildren) => {
  const [orgNumber, setOrgNumber] = useState<string>()
  const [workSituation, setWorkSituation] = useState<string>()
  const {
    data: companyResponse,
    isLoading,
    error,
    isSuccess,
    performFetch,
    resetState,
  } = useFetch<CompanyResponse>()

  useEffect(() => {
    if (orgNumber) {
      performFetch(buildQuery(orgNumber, workSituation), {
        headers: {
          'content-type': 'application/json',
        },
      })
    }
  }, [performFetch, orgNumber, workSituation])

  let company: Company = undefined

  if (orgNumber && companyResponse?.results?.length > 0) {
    company = {
      orgNumber,
      ...companyResponse.results[0],
    }
  }

  return (
    <CompanyContext.Provider
      value={{
        company,
        error,
        isSuccess,
        isLoading,
        setOrgNumber,
        setWorkSituation,
        resetState,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompany = (): UseCompanyProps => {
  const {
    isLoading,
    error,
    isSuccess,
    company,
    setOrgNumber,
    setWorkSituation,
    resetState,
  } = useContext<CompanyContextValues>(CompanyContext)

  const fetchCompany = (
    orgNumber: string,
    workSituation: string = undefined
  ) => {
    setOrgNumber(orgNumber)
    setWorkSituation(workSituation)
  }

  const resetCompany = () => {
    resetState()
    setOrgNumber(undefined)
  }

  return {
    isLoading,
    error,
    isSuccess,
    company,
    fetchCompany,
    resetCompany,
  }
}

export default useCompany
