import React, { PropsWithChildren } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

interface ButtonRowProps {
  marginBottom?: number
  marginTop?: number
}

const ButtonRow = ({
  children,
  marginBottom,
  marginTop,
}: PropsWithChildren<ButtonRowProps>) => {
  const sx: SxProps<Theme> = {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row' },
    gap: { xs: 1, lg: 'none' },
    marginRight: '-16px',
    marginLeft: '-16px',
  }

  return (
    <Box sx={sx} marginBottom={marginBottom} marginTop={marginTop}>
      {children}
    </Box>
  )
}

export default ButtonRow
