import { userCreationFinished } from '@trr/app-shell-communication'
import { useEffect, useState } from 'react'
import { isCookieEnabled } from 'Utils/Helpers/cookies'
import getConfig from 'Utils/Helpers/getConfig'

import useCompany from '../useCompany'
import useFetch from '../useFetch'
import useUser, { WorkSituations } from '../useUser'

export interface PostGatheredInformationToBe {
  createUser: () => void
  isSuccess: boolean
  isPosting: boolean
  error: Error
}

interface PostUser {
  companyId?: string
  orgNumber?: string
  workSituation?: string
  workRole?: string[]
  arbetsgivarinfoId?: string
}

const useCreateUser = (): PostGatheredInformationToBe => {
  const { company } = useCompany()
  const [isUserCreationFinished, setIsUserCreationFinished] = useState(false)

  const {
    workSituation,
    isUserContactLess,
    isMissingWorkSituation,
    linkIdFromUrl,
    isArbetsgivarinfoUser,
  } = useUser()
  const {
    isSuccess,
    isLoading: isPosting,
    error,
    performFetch,
  } = useFetch<string>()

  useEffect(() => {
    const leaveLoginFlow =
      (!isUserContactLess && isMissingWorkSituation) || isArbetsgivarinfoUser
    const isTestUser = isCookieEnabled('force-redirect-test-user')
    // When running Cypress tests we need a way to force-leave the loginflow due to
    // A generated user profile from the backend setting us in a dead state otherwise
    // This is handled through a cookie that is only used here.
    if (isSuccess && isTestUser && !isUserCreationFinished) {
      userCreationFinished(true)
      setIsUserCreationFinished(true)
    } else if (isSuccess && !isUserCreationFinished) {
      userCreationFinished(leaveLoginFlow)
      setIsUserCreationFinished(true)
    }
  }, [
    isSuccess,
    isUserContactLess,
    isMissingWorkSituation,
    isUserCreationFinished,
    isArbetsgivarinfoUser,
  ])

  const createUser = (): void => {
    let postUser: PostUser = {}

    // We don't send company and role if none exist
    if (company) {
      postUser = {
        companyId: company.id,
        orgNumber: company.orgNumber,
        workRole: [],
      }
    }

    if (workSituation) {
      postUser.workSituation = workSituation
    }

    if (linkIdFromUrl) {
      postUser.arbetsgivarinfoId = linkIdFromUrl
      postUser.workSituation = WorkSituations.employed
    }

    performFetch(`${getConfig().PUBLIC_WEB_API_URL}/user`, {
      method: 'POST',
      body: JSON.stringify(postUser),
      headers: {
        'content-type': 'application/json',
      },
    })
  }

  return { createUser, isPosting, isSuccess, error }
}

export default useCreateUser
