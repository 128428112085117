import React, { useEffect, useState } from 'react'
import { userCreationFinished } from '@trr/app-shell-communication'
import usePages from 'Utils/Hooks/usePages'
import { CreatingAccountLoader, PageTitle } from 'Components'
import {
  Typography,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import BasePage from '../BasePage'
import useFetch from '../../Utils/Hooks/useFetch'
import getConfig from '../../Utils/Helpers/getConfig'

const ContactInformationPage = () => {
  const [verificationCode, setVerificationCode] = useState<string>(undefined)
  const [emailAddress, setEmailAddress] = useState<string>(undefined)
  const [hasSentVerificationCode, setHasSentVerificationCode] =
    useState<boolean>(undefined)
  const [hasAcceptedAgreement, setHasAcceptedAgreement] =
    useState<boolean>(false)

  const [emailIsLoading, setEmailIsLoading] = useState<boolean>(false)
  const [verificationIsLoading, setVerificationIsLoading] =
    useState<boolean>(false)

  const [verificationCodeSendError, setVerificationCodeSendError] =
    useState<string>('')

  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')

  const sendEmailFetch = useFetch()
  const sendVerificationCodeFetch = useFetch()
  const { isTermsAndConditionsEnabled } = usePages()

  const validateEmail = (email: string) => {
    const validEmailFormat = /^.+@.+$/

    if (email === undefined || email === '') {
      return 'Fyll i din e-postadress'
    } else if (email.length > 241) {
      return 'Max 241 tecken'
    } else if (!validEmailFormat.test(email)) {
      return 'Ange en giltig e-postadress'
    } else {
      return ''
    }
  }

  const sendEmail = (emailAddress: string) => {
    sendEmailFetch.performFetch(
      `${getConfig().PUBLIC_WEB_API_URL}/user/contact/email`,
      {
        method: 'PUT',
        body: JSON.stringify(emailAddress),
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
  }

  const fetchVerificationCode = (code: string) => {
    if (!code) {
      return
    }

    setVerificationIsLoading(true)
    sendVerificationCodeFetch.performFetch(
      `${getConfig().PUBLIC_WEB_API_URL}/user/contact/email/verification`,
      {
        method: 'PUT',
        body: JSON.stringify(code),
        headers: {
          'content-type': 'application/json',
        },
      }
    )
  }

  const sendEmailVerification = () => {
    const emailValidation = validateEmail(emailAddress)
    if (emailValidation === '') {
      sendEmail(emailAddress)
    } else {
      setEmailErrorMessage(emailValidation)
    }
  }

  const sendVerificationCode = () => {
    if (
      !verificationCode ||
      (isTermsAndConditionsEnabled && !hasAcceptedAgreement)
    ) {
      setVerificationCodeSendError('Ange en kod')
    } else {
      fetchVerificationCode(verificationCode)
    }
  }

  useEffect(() => {
    setEmailIsLoading(sendEmailFetch.isLoading)
  }, [sendEmailFetch.isLoading])

  useEffect(() => {
    setVerificationIsLoading(sendVerificationCodeFetch.isLoading)
  }, [sendVerificationCodeFetch.isLoading])

  useEffect(() => {
    if (sendVerificationCodeFetch.isSuccess) {
      userCreationFinished(true)
      setHasSentVerificationCode(true)
    }
  }, [sendVerificationCodeFetch.isSuccess])

  useEffect(() => {
    if (sendVerificationCodeFetch.error) {
      setVerificationCodeSendError('Koden är fel eller gammal')
      setVerificationCode(undefined)
    }
  }, [sendVerificationCodeFetch.error])

  return (
    <>
      {emailIsLoading || verificationIsLoading || hasSentVerificationCode ? (
        <CreatingAccountLoader testSelector={'Loader'} />
      ) : (
        <BasePage showUserInformation={false}>
          <PageTitle>Lägg till din e-postadress</PageTitle>
          <Typography
            gutterBottom
            variant={'h6'}
            sx={{ whiteSpace: { md: 'nowrap' } }}
          >
            Fyll i din e-postadress och beställ verifieringskod
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              name="email"
              autoComplete="on"
              aria-invalid={emailErrorMessage.length > 0}
              helperText={emailErrorMessage}
              error={Boolean(emailErrorMessage)}
              inputProps={{ 'data-testid': 'emailInputField' }}
              label="E-postadress"
              value={emailAddress}
              onChange={(event) => {
                setEmailAddress(event.target.value)
                setEmailErrorMessage('')
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  sendEmailVerification()
                }
              }}
              sx={{ paddingBottom: 1.9, width: { xs: '100%', sm: '450px' } }}
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}
            >
              <Button
                variant="outlined"
                sx={{ width: { xs: '100%', sm: '250px' } }}
                data-testid="emailSendButton"
                onClick={() => {
                  sendEmailVerification()
                }}
              >
                Beställ verifieringskod
              </Button>
              {sendEmailFetch.isSuccess && (
                <Typography variant="body2">Koden har skickats</Typography>
              )}
            </Box>
          </Box>
          <Typography sx={{ paddingBottom: 1 }} variant={'h6'}>
            Ange verifieringskod
          </Typography>
          <Typography sx={{ paddingBottom: 2 }} variant={'body2'}>
            Koden skickas till din e-post. Det kan ta några minuter innan mejlet
            kommer fram.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              aria-invalid={verificationCodeSendError.length > 0}
              helperText={verificationCodeSendError}
              error={Boolean(verificationCodeSendError)}
              inputProps={{ 'data-testid': 'validationInput' }}
              label="Verifieringskod"
              onChange={(event) => {
                setVerificationCode(event.target.value)
                setVerificationCodeSendError('')
              }}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  isTermsAndConditionsEnabled &&
                  hasAcceptedAgreement
                ) {
                  sendVerificationCode()
                }
              }}
              sx={{ paddingBottom: 2.5, width: { xs: '100%', sm: '250px' } }}
            />
            {isTermsAndConditionsEnabled && (
              <FormControlLabel
                control={<Checkbox />}
                label="Jag godkänner TRRs villkor (för kund/arbetsgivare)"
                checked={hasAcceptedAgreement}
                data-testid="confirmationCheckbox"
                onChange={() => {
                  setHasAcceptedAgreement(!hasAcceptedAgreement)
                }}
                sx={{ paddingBottom: 3.5 }}
              />
            )}
            <Button
              variant="contained"
              sx={{ marginBottom: 6, width: { xs: '100%', sm: '150px' } }}
              data-testid="validationSendButton"
              disabled={isTermsAndConditionsEnabled && !hasAcceptedAgreement}
              onClick={() => {
                sendVerificationCode()
              }}
            >
              Fortsätt
            </Button>
          </Box>
          <Typography variant={'subtitle1'}>Fick du ingen kod?</Typography>
          <Button
            variant="text"
            sx={{ padding: 0 }}
            size="small"
            onClick={() => {
              sendEmailVerification()
            }}
          >
            Beställ igen
          </Button>
        </BasePage>
      )}
    </>
  )
}

export default ContactInformationPage
