import React, { ReactNode, useContext } from 'react'
import { Authentication } from 'App.types'

export const AuthenticationContext =
  React.createContext<Authentication>(undefined)

export interface IAuthenticationProvider {
  authentication: Authentication
  children?: ReactNode
}

export const AuthenticationProvider = ({
  authentication,
  children,
}: IAuthenticationProvider) => {
  return (
    <AuthenticationContext.Provider value={authentication}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = (): Authentication => {
  const { profile, idToken } = useContext<Authentication>(AuthenticationContext)

  return {
    profile: profile,
    idToken,
  }
}

export default useAuthentication
