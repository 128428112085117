export interface Cookies {
  [key: string]: string
}

export enum cookieNames {
  'username' = 'trr-loginflow-usernamepassword',
}

export const isCookieEnabled = (key: string): boolean => {
  let result = false

  try {
    const cookies = document.cookie
      .split(';')
      .reduce((acc: Cookies, cookie): Cookies => {
        const [cookieKey, ...rest] = cookie.split('=')
        acc[cookieKey.trim()] = rest.join('=')
        return acc
      }, {})

    const cookieValue = cookies[key]

    if (cookieValue === 'true') {
      result = true
    }
  } catch (e) {
    result = false
  }
  return result
}

export const isTrrLoginFlowUsernameCookieSet = (): boolean => {
  return isCookieEnabled(cookieNames.username)
}
