import { baseApi } from 'State/Api'
import {
  type CompanyRequestParams,
  type CompanyResponse,
  type CompanyResponseTransformed,
} from './types'

export const api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<CompanyResponseTransformed, CompanyRequestParams>(
      {
        query: ({ organizationNumber, workSituation }) => {
          const workSituationParam = workSituation
            ? `&worksituation=${workSituation}`
            : ''
          return `/workplace/search/membership-by-org-number?query=${organizationNumber}${workSituationParam}`
        },
        transformResponse: (res: CompanyResponse) => {
          if (res?.results?.length > 0) {
            return res.results[0]
          }
          return undefined
        },
      }
    ),
  }),
})

export const { useLazyGetCompanyQuery, useGetCompanyQuery } = api

export const useGetCompanyQueryState = api.endpoints.getCompany.useQueryState

export default api
