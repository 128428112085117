import { useMemo, useCallback } from 'react'
import {
  redirectToAuthServer,
  redirectToAuthServerEmployer,
} from '@trr/app-shell-communication'
import { BankIdImage, PasswordImage, FrejaImage } from 'Assets'
import useTexts from 'Utils/Hooks/useTexts'
import useLoginFlowUser from 'Utils/Hooks/useLoginFlowUser'
import { isTrrLoginFlowUsernameCookieSet } from 'Utils/Helpers/cookies'
import { UserTypes } from 'App.types'
import { List, Divider } from '@mui/material'

import BasePage from '../BasePage'
import { RadioButtonGroup, RadioButton, PageTitle } from '../../Components'

import texts from './texts'
import { ListItem } from './Components'

const LoginPage = () => {
  const {
    userType,
    changeUserType,
    isDisplayUsernameFeatureFlagEnabled,
    device,
    isCustomLogin,
    isArbetsgivarinfoUser,
  } = useLoginFlowUser()
  const pageTexts = useTexts(texts)

  const loginClicked = useCallback(
    (authType: string) => () => {
      if (userType === UserTypes.tjansteman) {
        redirectToAuthServer(authType)
        return
      }
      redirectToAuthServerEmployer(authType)
    },
    [userType]
  )

  const isDeviceLargerThanMobile = useMemo(() => !device.isMobile, [device])

  const shouldPasswordBeVisible =
    isDisplayUsernameFeatureFlagEnabled || isTrrLoginFlowUsernameCookieSet()

  return (
    <BasePage>
      <PageTitle>{pageTexts.login}</PageTitle>
      <RadioButtonGroup
        displayRow={isDeviceLargerThanMobile}
        isHidden={isArbetsgivarinfoUser}
      >
        <RadioButton
          checked={userType === UserTypes.tjansteman}
          label={pageTexts.loginAtTrr}
          testId="tjansteman-radiobutton"
          onClick={() => changeUserType(UserTypes.tjansteman)}
        />
        <RadioButton
          checked={userType === UserTypes.arbetsgivare}
          label={pageTexts.loginAsEmployer}
          testId="arbetsgivare-radiobutton"
          onClick={() => changeUserType(UserTypes.arbetsgivare)}
        />
      </RadioButtonGroup>
      <List>
        <Divider />
        {isCustomLogin ? (
          <ListItem
            label={pageTexts.password}
            testId="password"
            icon={<PasswordImage aria-hidden="true" />}
            onClick={loginClicked('password')}
          />
        ) : (
          <>
            <ListItem
              label={pageTexts.bThis}
              testId="bankid-this-device"
              icon={<BankIdImage aria-hidden="true" />}
              onClick={loginClicked('bThis')}
            />
            <ListItem
              label={pageTexts.bOther}
              testId="bankid-other-device"
              icon={<BankIdImage aria-hidden="true" />}
              onClick={loginClicked('bOther')}
            />
            <ListItem
              label={pageTexts.username}
              testId="username"
              hidden={!shouldPasswordBeVisible}
              icon={<PasswordImage aria-hidden="true" />}
              onClick={loginClicked('username')}
            />
            <ListItem
              label={pageTexts.freja}
              testId="freja-other-device"
              icon={<FrejaImage aria-hidden="true" />}
              onClick={loginClicked('fOther')}
            />
            <ListItem
              label={pageTexts.password}
              testId="password"
              hidden={!shouldPasswordBeVisible}
              icon={<PasswordImage aria-hidden="true" />}
              onClick={loginClicked('password')}
            />
          </>
        )}
      </List>
    </BasePage>
  )
}

export default LoginPage
