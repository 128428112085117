import { PropsWithChildren } from 'react'
import {
  Box,
  CircularProgress,
  Typography,
  type SxProps,
  type Theme,
} from '@mui/material'

interface CreatingAccountLoaderProps {
  testSelector?: string
}

const loaderBoxStyle: SxProps<Theme> = {
  display: 'flex',
  height: '75%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}

const CreatingAccountLoader = ({
  testSelector,
  children = 'Förbereder nästa steg...',
}: PropsWithChildren<CreatingAccountLoaderProps>) => (
  <Box sx={loaderBoxStyle}>
    <CircularProgress
      data-testid={testSelector}
      size={40}
      sx={{ marginBottom: 2 }}
    />
    <Typography variant={'body2'}>{children}</Typography>
  </Box>
)

export default CreatingAccountLoader
