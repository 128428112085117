import { useMemo } from 'react'
import useLoginFlowUser from 'Utils/Hooks/useLoginFlowUser'

interface SubCategory {
  [key: string]: string
}

export interface TextsObject {
  [key: string]: string | SubCategory
}

export interface ReducedTextsObject {
  [key: string]: string
}

const useTexts = (texts: TextsObject): ReducedTextsObject => {
  const { userType: subCategoryKey } = useLoginFlowUser()

  // Create key-value object based on user type
  const reducedTextsObject: ReducedTextsObject = useMemo(() => {
    return Object.keys(texts).reduce((acc: ReducedTextsObject, key: string) => {
      const value = texts[key]
      acc[key] = typeof value === 'object' ? value[subCategoryKey] : value
      return acc
    }, {})
  }, [texts, subCategoryKey])

  return reducedTextsObject
}

export default useTexts
