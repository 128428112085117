import { UserTypes } from 'App.types'

import LoginPage from './LoginPage'
import SelectTjanstemanPage from './SelectTjanstemanPage'
import NoTjanstemanPage from './NoTjanstemanPage'
import CurrentArbetssituationPage from './CurrentArbetssituationPage'
import SelectArbetsgivarePage from './SelectArbetsgivarePage'
import ArbetsgivareSelectedPage from './ArbetsgivareSelectedPage'
import VerifyArbetsgivarePage from './VerifyArbetsgivarePage'
import ContactInformationPage from './ContactInformationPage'
import ArbetsgivarinfoConfirmationPage from './ArbetsgivarinfoConfirmationPage'

export const pageIds = {
  login: 'login',
  selectArbetsgivare: 'selectArbetsgivare',
  verifyArbetsgivare: 'verifyArbetsgivare',
  arbetsgivareSelected: 'arbetsgivareSelected',
  currentArbetssituation: 'currentArbetsituation',
  contactInformation: 'contactInformation',
  noTjanstemanPage: 'noTjanstemanPage',
  selectTjanstemanPage: 'selectTjanstemanPage',
  arbetsgivarinfoConfirmationPage: 'arbetsgivarinfoConfirmationPage',
}

export const pageIdMap: Record<string, () => JSX.Element> = {
  [pageIds.login]: LoginPage,
  [pageIds.noTjanstemanPage]: NoTjanstemanPage,
  [pageIds.selectTjanstemanPage]: SelectTjanstemanPage,
  [pageIds.selectArbetsgivare]: SelectArbetsgivarePage,
  [pageIds.verifyArbetsgivare]: VerifyArbetsgivarePage,
  [pageIds.arbetsgivareSelected]: ArbetsgivareSelectedPage,
  [pageIds.currentArbetssituation]: CurrentArbetssituationPage,
  [pageIds.contactInformation]: ContactInformationPage,
  [pageIds.arbetsgivarinfoConfirmationPage]: ArbetsgivarinfoConfirmationPage,
}

// Controls in which order pages are displayed for what user type

export const pageFlow = {
  [UserTypes.tjansteman]: [
    pageIds.login,
    pageIds.noTjanstemanPage,
    pageIds.selectTjanstemanPage,
    pageIds.currentArbetssituation,
    pageIds.selectArbetsgivare,
    pageIds.verifyArbetsgivare,
    pageIds.contactInformation,
  ],
  [UserTypes.arbetsgivare]: [
    pageIds.login,
    pageIds.selectArbetsgivare,
    pageIds.arbetsgivareSelected,
    pageIds.contactInformation,
  ],
}

const generateGetPageByIndexOffset =
  (modifier: number) =>
  (userType: UserTypes, pageId: string): string | undefined => {
    const flow = pageFlow[userType]
    const currentPageIndex: number = flow.indexOf(pageId)
    const nextPageIndex = currentPageIndex + modifier
    return flow[nextPageIndex]
  }

export const getPrevPageId = generateGetPageByIndexOffset(-1)
export const getNextPageId = generateGetPageByIndexOffset(1)
