import BasePage from 'Pages/BasePage'

import useTexts from 'Utils/Hooks/useTexts'
import { PageTitle } from 'Components'
import { Typography } from '@mui/material'

import texts from './texts'

const NoTjanstemanPage = () => {
  const pageTexts = useTexts(texts)

  return (
    <BasePage>
      <PageTitle>{pageTexts.noSupportHeader}</PageTitle>
      <Typography variant="body1" mb={5}>
        {pageTexts.noSupportText1}
      </Typography>
      <Typography variant="body1" mb={5}>
        {pageTexts.noSupportText2}
      </Typography>
    </BasePage>
  )
}

export default NoTjanstemanPage
