import getConfig from 'Utils/Helpers/getConfig'
import { ArbetsgivarinfoApiResponse } from 'App.types'

import useUser from '../useUser'
import useFetch from '../useFetch'

export interface UseGetArbetsgivarinfo {
  getArbetsgivarinfo: () => void
  arbetsgivarinfo: ArbetsgivarinfoApiResponse
  getArbetsgivarinfoIsSuccess: boolean
  getArbetsgivarinfoIsLoading: boolean
  getArbetsgivarinfoError: Error
}

const useGetArbetsgivarinfo = (): UseGetArbetsgivarinfo => {
  const { linkIdFromUrl } = useUser()
  const {
    data: arbetsgivarinfo,
    isSuccess: getArbetsgivarinfoIsSuccess,
    isLoading: getArbetsgivarinfoIsLoading,
    error: getArbetsgivarinfoError,
    performFetch,
  } = useFetch<ArbetsgivarinfoApiResponse>()

  const getArbetsgivarinfo = (): void => {
    performFetch(
      `${getConfig().PUBLIC_WEB_API_URL}/arbetsgivarinfo/${linkIdFromUrl}`
    )
  }

  return {
    getArbetsgivarinfo,
    arbetsgivarinfo,
    getArbetsgivarinfoIsSuccess,
    getArbetsgivarinfoIsLoading,
    getArbetsgivarinfoError,
  }
}

export default useGetArbetsgivarinfo
