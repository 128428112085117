/* eslint @typescript-eslint/no-floating-promises: 0 */ //The linter is complaining about a floating-promise here, but the promise is fulfilled within the abstraction - pretty sure that this is only about the linter not understanding the context, hence the ignore.
import { useAuthentication } from '@trr/app-shell-data'
import { useCallback, useReducer } from 'react'

interface State<T> {
  data?: T
  isLoading: boolean
  isSuccess: boolean
  error?: Error
}
interface UseFetch<T> extends State<T> {
  performFetch: (url?: string, options?: RequestInit) => void
  resetState: () => void
}

type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error }
  | { type: 'reset' }

const useFetch = <T = unknown>(): UseFetch<T> => {
  const initialState: State<T> = {
    error: undefined,
    isLoading: false,
    isSuccess: false,
    data: undefined,
  }

  const fetchReducer = (prevState: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, isLoading: true }
      case 'fetched':
        return { ...initialState, isSuccess: true, data: action.payload }
      case 'error':
        return { ...initialState, isSuccess: false, error: action.payload }
      case 'reset':
        return { ...initialState }
      default:
        return prevState
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)
  const { idToken } = useAuthentication()

  const performFetch = useCallback(
    (url: string, options?: RequestInit) => {
      dispatch({ type: 'loading' })

      if (idToken) {
        options = {
          ...options,
          headers: {
            Authorization: `Bearer ${idToken}`,
            ...options?.headers,
          },
        }
      }

      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.text()
          }
        })
        .then((jsonAsText) => {
          let data: T
          try {
            data = JSON.parse(jsonAsText) as T
          } catch {
            // if we can't parse the response to a JSON object, we assume that it's a text response. This is hacky and should maybe be re-worked
            data = jsonAsText as T
          }
          dispatch({ type: 'fetched', payload: data })
        })
        .catch((reason) => {
          dispatch({ type: 'error', payload: reason as Error })
        })
    },
    [idToken]
  )

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return { ...state, performFetch, resetState }
}

export default useFetch
