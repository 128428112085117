import { useSelector } from 'react-redux'
import { type RootState } from 'State/Store'

type sliceRootState = Pick<RootState, 'slices'>

const useSliceStateSelector = <TResult>(
  selector: (state: sliceRootState) => TResult
): TResult => useSelector<RootState, TResult>(selector)

export default useSliceStateSelector
