import { pageIds } from 'Pages'
import React, { PropsWithChildren } from 'react'
import usePages from 'Utils/Hooks/usePages'
import useUser from 'Utils/Hooks/useUser'
import PersonalInformation from 'Components/PersonalInformation'
import { Box, SxProps, Theme } from '@mui/material'

import useCompany from '../../Utils/Hooks/useCompany'

interface BasePageProps {
  testId?: string
  loggedIn?: boolean
  showUserInformation?: boolean
}

const BasePage = ({
  testId,
  showUserInformation = true,
  children,
}: PropsWithChildren<BasePageProps>) => {
  const { isUserOnboarding, isUserContactLess, user } = useUser()
  const { currentPageId } = usePages()
  const { company } = useCompany()

  const showCompanyForPages = [
    pageIds.verifyArbetsgivare,
    pageIds.currentArbetssituation,
    pageIds.arbetsgivareSelected,
    pageIds.contactInformation,
    pageIds.arbetsgivarinfoConfirmationPage,
  ]

  const showCompany = showCompanyForPages.includes(currentPageId)

  const shouldShowUserInformation =
    showUserInformation && (isUserOnboarding || isUserContactLess)

  type StyleNames = 'basePage' | 'content'
  const basePageStyles: Record<StyleNames, SxProps<Theme>> = {
    basePage: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      marginBottom: 9,
    },
  }

  return (
    <Box sx={basePageStyles.basePage} data-testid={testId}>
      {shouldShowUserInformation && (
        <PersonalInformation user={user} company={showCompany && company} />
      )}
      <Box sx={basePageStyles.content}>{children}</Box>
    </Box>
  )
}

export default BasePage
