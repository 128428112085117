import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { baseApi } from '../Api'
import { slices } from 'State/Slices'

const rootReducer = combineReducers({
  slices,
  [baseApi.reducerPath]: baseApi.reducer,
})

export const createStore = (preloadedState?: DeepPartial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat([baseApi.middleware])
    },
    preloadedState,
  })
  setupListeners(store.dispatch)
  return store
}
const store = createStore()

setupListeners(store.dispatch)

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type RootState = ReturnType<typeof rootReducer>

export default store
