import BasePage from 'Pages/BasePage'
import React, { useState } from 'react'
import usePages from 'Utils/Hooks/usePages'
import useTexts from 'Utils/Hooks/useTexts'
import { Link, Button } from '@mui/material'

import { RadioButtonGroup, RadioButton, PageTitle } from '../../Components'

import texts from './texts'
import { TjanstemanDialog } from './Components'

const SelectTjanstemanPage = () => {
  const { goToNextPage, goToPrevPage } = usePages()

  const [tjansteman, setTjansteman] = useState<boolean>()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const pageTexts = useTexts(texts)

  const nextClicked = () => {
    if (tjansteman) {
      goToNextPage()
    } else {
      goToPrevPage()
    }
  }

  const nextEnabled = tjansteman === true || tjansteman === false
  const openDialog = () => {
    setDialogOpen(true)
  }

  return (
    <BasePage>
      <PageTitle>{pageTexts.header}</PageTitle>
      <RadioButtonGroup displayRow={false} marginBottom={3}>
        <RadioButton
          label={pageTexts.yesLabel}
          checked={tjansteman === true}
          testId="iAmTjansteman"
          onClick={() => setTjansteman(true)}
        />
        <RadioButton
          label={pageTexts.noLabel}
          checked={tjansteman === false}
          testId="iAmNotTjansteman"
          onClick={() => setTjansteman(false)}
        />
      </RadioButtonGroup>

      <Link
        component="button"
        display="block"
        variant="body1"
        onClick={openDialog}
        data-testid="tjanstemanExplanationLink"
      >
        {pageTexts.tjanstemanExplanation}
      </Link>
      <Button
        data-testid="forward-button"
        variant="contained"
        onClick={nextClicked}
        disabled={!nextEnabled}
        sx={{ marginTop: 5, width: { xs: '100%', sm: 'auto' } }}
      >
        Fortsätt
      </Button>

      <TjanstemanDialog
        dialogOpen={dialogOpen}
        pageTexts={pageTexts}
        closeDialog={() => setDialogOpen(false)}
      />
    </BasePage>
  )
}

export default SelectTjanstemanPage
