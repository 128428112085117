import { useCallback } from 'react'
import {
  CreatingAccountLoader,
  RadioButtonGroup,
  RadioButton,
  PageTitle,
} from 'Components'
import useLoginFlowUser, { WorkSituations } from 'Utils/Hooks/useLoginFlowUser'
import usePages from 'Utils/Hooks/usePages'
import useCreateUser from 'Utils/Hooks/useCreateUser'
import { Button } from '@mui/material'

import BasePage from '../BasePage'

const CurrentArbetssituationPage = () => {
  const { setWorkSituation, workSituation, isMissingWorkSituation } =
    useLoginFlowUser()

  const {
    createUser,
    isPosting: isPostingUser,
    isSuccess: isUserCreated,
  } = useCreateUser()

  const showLoading = isPostingUser || isUserCreated

  const { goToNextPage } = usePages()

  const nextClicked = useCallback(() => {
    if (isMissingWorkSituation) {
      createUser()
    } else {
      goToNextPage()
    }
  }, [goToNextPage, isMissingWorkSituation, createUser])

  const nextEnabled = Object.values(WorkSituations).includes(workSituation)

  return (
    <>
      {showLoading ? (
        <CreatingAccountLoader testSelector={'LoadingElement'} />
      ) : (
        <BasePage>
          <PageTitle>Vad är din nuvarande arbetssituation?</PageTitle>
          <RadioButtonGroup displayRow={false} marginBottom={6}>
            <RadioButton
              checked={workSituation === WorkSituations.employed}
              label="Jag är anställd"
              testId="iAmEmployedRadioButton"
              onClick={() => setWorkSituation(WorkSituations.employed)}
            />
            <RadioButton
              checked={workSituation === WorkSituations.unemployed}
              label="Jag är uppsagd eller nyligen arbetslös"
              testId="TIO-radio-button"
              onClick={() => setWorkSituation(WorkSituations.unemployed)}
            />
          </RadioButtonGroup>

          <Button
            variant="contained"
            disabled={!nextEnabled}
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            data-testid="forward-button"
            onClick={nextClicked}
          >
            Fortsätt
          </Button>
        </BasePage>
      )}
    </>
  )
}

export default CurrentArbetssituationPage
