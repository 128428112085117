// ** NoTjanstemanPage **

import { TextsObject } from 'Utils/Hooks/useTexts'

const texts: TextsObject = {
  noSupportHeader: 'Tyvärr, du kan inte ansöka om stöd av TRR.',
  noSupportText1:
    'Våra stöd kan endast nyttjas av de som arbetar som tjänstemän på ett TRR-anslutet företag, dvs att din anställning går under ett tjänstemannaavtal.',
  noSupportText2:
    'För dig som är exempelvis är arbetare, och har arbetsuppgifter där ett arbetareavtal gäller, finns andra trygghetsorganisationer som erbjuder stöd. Fråga din arbetsgivare vilka stödverksamheter de samarbetar med.',
  startPageButton: 'Till startsidan',
  options: 'Andra alternativ',
  otherActorsLink: 'Visa andra aktörer för arbetslivsstöd',
}

export default texts
