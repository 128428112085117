import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { InputsState } from './types'

const initialState: InputsState = {
  organizationNumber: undefined,
  workSituation: undefined,
}

const inputsSlice = createSlice({
  name: 'inputs',
  initialState,
  reducers: {
    setOrganizationNumber: (
      state,
      action: PayloadAction<InputsState['organizationNumber']>
    ): InputsState => ({
      ...state,
      organizationNumber: action.payload,
    }),
    setWorkSituation: (
      state,
      action: PayloadAction<InputsState['workSituation']>
    ): InputsState => ({
      ...state,
      workSituation: action.payload,
    }),
  },
})

export default inputsSlice
