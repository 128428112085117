import { PropsWithChildren } from 'react'
import { Typography, type SxProps, type Theme } from '@mui/material'

interface PageTitleProps {
  sx?: SxProps<Theme>
}

const PageTitle = ({ children, sx }: PropsWithChildren<PageTitleProps>) => {
  return (
    <Typography variant="h2" sx={{ marginBottom: 3, ...sx }}>
      {children}
    </Typography>
  )
}

export default PageTitle
