import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  type Theme,
  Typography,
} from '@mui/material'
import { ReducedTextsObject } from 'Utils/Hooks/useTexts'

interface TjanstemanDialogProps {
  closeDialog: () => void
  pageTexts: ReducedTextsObject
  dialogOpen: boolean
}

const TjanstemanDialog = ({
  closeDialog,
  pageTexts,
  dialogOpen,
}: TjanstemanDialogProps) => (
  <Dialog
    open={dialogOpen}
    onClose={closeDialog}
    data-testid="tjansteman-dialog"
  >
    <DialogTitle>
      {pageTexts.tjanstemanExplanation}
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme: Theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography mb={2}>{pageTexts.tjanstemanExplanationBody}</Typography>
    </DialogContent>
  </Dialog>
)

export default TjanstemanDialog
