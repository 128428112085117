// ** SelectTjanstemanPage **

import { TextsObject } from 'Utils/Hooks/useTexts'

const texts: TextsObject = {
  header: 'Arbetar du som tjänsteman?',
  yesLabel: 'Ja, jag arbetar som tjänsteman',
  noLabel: 'Nej, jag arbetar inte som tjänsteman',
  tjanstemanExplanation: 'Vad är en tjänsteman?',
  tjanstemanExplanationBody1:
    'Våra stöd kan endast nyttjas av tjänstemän som arbetar på TRR-anslutna företag. Tjänstemän är de som arbetar på kontor eller med förberedande arbetsuppgifter. Tjänstemän går under ett tjänstemannaavtal och är därför medlemmar i ett TCO- eller ett SACO-förbund.',
  tjanstemanExplanationBody2:
    'För dig som är arbetare, och har arbetsuppgifter där ett arbetareavtal gäller, finns andra trygghetsorganisationer som erbjuder stöd.',
  tjanstemanExplanationBody3:
    'Fråga din arbetsgivare om du är osäker på vilken yrkeskategori du tillhör.',
  continueButton: 'Fortsätt',
  startPageButton: 'Till startsidan',
}

export default texts
