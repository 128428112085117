import React, { useState } from 'react'
import { ButtonRow, CreatingAccountLoader, PageTitle } from 'Components'
import usePages from 'Utils/Hooks/usePages'
import useCreateUser from 'Utils/Hooks/useCreateUser'
import { Typography, Button, FormControlLabel, Checkbox } from '@mui/material'

import BasePage from '../BasePage'
import useCompany from '../../Utils/Hooks/useCompany'

const VerifyArbetsgivarePage = () => {
  const [arbetsgivareVerified, setArbetsgivareVerified] = useState(false)
  const { isLoading: isLoadingCompany, company, resetCompany } = useCompany()

  const {
    createUser,
    isPosting: isPostingUser,
    isSuccess: isUserCreated,
  } = useCreateUser()

  const { goToPrevPage, isTermsAndConditionsEnabled } = usePages()

  const handleGoBack = () => {
    resetCompany()
    goToPrevPage()
  }

  const showLoading = isLoadingCompany || isPostingUser || isUserCreated

  const OrganizationNotFound = () => {
    return (
      <BasePage>
        <div data-testid="OrganizationNotFound">
          <PageTitle>
            Din arbetsgivare är tyvärr inte ansluten till TRR
          </PageTitle>

          <Typography variant={'body2'} mb={5}>
            För att ha rätt till vårt stöd behöver din arbetsgivare vara
            ansluten till oss. Fråga din arbetsgivare vilka stödverksamheter de
            samarbetar med.
          </Typography>

          <Typography variant={'body2'} mb={5}>
            Dina inloggningsuppgifter kommer att tas bort från vårt system i
            enlighet med GDPR. Du kan alltid prova att logga in igen om din
            situation skulle förändras.
          </Typography>
          <Button
            data-testid="goBackButton"
            onClick={handleGoBack}
            variant="outlined"
          >
            Gör en ny sökning
          </Button>
        </div>
      </BasePage>
    )
  }

  const OrganizationFound = () => {
    return (
      <BasePage>
        <Typography
          data-testid="organizationFound"
          variant="h2"
          sx={{ marginBottom: { xs: 2, md: 1.5 } }}
        >
          Toppen, som tjänsteman på det här företaget kan du ansöka om stöd av
          oss.
        </Typography>
        {isTermsAndConditionsEnabled && (
          <>
            <Typography variant="body2" gutterBottom>
              Godkänn villkoren för att gå vidare.
            </Typography>

            <FormControlLabel
              checked={arbetsgivareVerified}
              onChange={() => setArbetsgivareVerified(!arbetsgivareVerified)}
              label="Jag uppfyller TRRs villkor för att få stöd"
              control={<Checkbox />}
            />
          </>
        )}
        <ButtonRow marginTop={!isTermsAndConditionsEnabled && 6}>
          <Button
            sx={{ marginX: 1 }}
            variant="contained"
            disabled={isTermsAndConditionsEnabled && !arbetsgivareVerified}
            onClick={createUser}
            data-testid="forward-button"
          >
            Fortsätt
          </Button>
          <Button
            sx={{ marginX: 1 }}
            data-testid="goBackButton"
            onClick={handleGoBack}
            variant="outlined"
          >
            Ange annan arbetsgivare
          </Button>
        </ButtonRow>
      </BasePage>
    )
  }

  const OrganizationViewToRender = () => {
    if (company) {
      return <OrganizationFound />
    } else {
      return <OrganizationNotFound />
    }
  }

  return (
    <>
      {showLoading ? (
        <CreatingAccountLoader testSelector={'LoadingElement'} />
      ) : (
        <OrganizationViewToRender />
      )}
    </>
  )
}

export default VerifyArbetsgivarePage
