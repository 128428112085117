// ** SelectTjanstemanPage **

import { TextsObject } from 'Utils/Hooks/useTexts'

const texts: TextsObject = {
  header: 'Arbetar du som tjänsteman?',
  yesLabel: 'Ja, jag arbetar som tjänsteman',
  noLabel: 'Nej, jag arbetar inte som tjänsteman',
  tjanstemanExplanation: 'Är du tjänsteman i ett TRR-anslutet företag?',
  tjanstemanExplanationBody:
    'Vårt stöd är tillgängligt för dig som arbetar i ett TRR-anslutet företag och omfattas av ett kollektivavtal för tjänstemän. Detta avtal är tecknat mellan din arbetsgivare och ett TCO- och/eller SACO-förbund. Välkommen med din ansökan till TRR!',
  continueButton: 'Fortsätt',
  startPageButton: 'Till startsidan',
}

export default texts
