import React, {
  useContext,
  useState,
  useCallback,
  PropsWithChildren,
} from 'react'
import { IDevice, UserTypes } from 'App.types'
import { abortLogin } from '@trr/app-shell-communication'

const getUserTypeByUrl = (): UserTypes =>
  window.location.href.indexOf('/arbetsgivare') >= 0
    ? UserTypes.arbetsgivare
    : UserTypes.tjansteman

const getIsCustomLogin = () =>
  window.location.href.indexOf('/login/custom') >= 0

const getLinkIdFromUrl = () => {
  const urlParameters = new URLSearchParams(window.location.search)
  const linkId = urlParameters.get('linkId')

  return linkId
}

const getIsArbetsgivarinfoUser = () => {
  const arbetsgivarinfoPathname =
    window.location.pathname.includes('arbetsgivarinfo')
  const linkId = getLinkIdFromUrl()
  const urlHasLinkId = Boolean(linkId)

  const isArbetsgivarinfoLoginFlow = arbetsgivarinfoPathname && urlHasLinkId

  return isArbetsgivarinfoLoginFlow
}

export interface UserContextValues {
  userType: UserTypes
  setUserType: (userType: UserTypes) => void
  user: User
  isUserOnboarding: boolean
  isUserContactLess: boolean
  setWorkSituation: (workSituation: string) => void
  workSituation: string
  isDisplayUsernameFeatureFlagEnabled: boolean
  isMissingWorkSituation: boolean
  device: IDevice
  isCustomLogin: boolean
  isArbetsgivarinfoUser: boolean
  linkIdFromUrl?: string
}

export const UserContext = React.createContext<UserContextValues>(undefined)

export interface User {
  name?: string
  socialSecurityNumber?: string
}

interface UserProviderProps {
  user: User
  isUserOnboarding: boolean
  isUserContactLess: boolean
  isDisplayUsernameFeatureFlagEnabled: boolean
  isMissingWorkSituation: boolean
  device: IDevice
}

interface IWorkSitations {
  employed: string
  unemployed: string
}

export const WorkSituations: IWorkSitations = {
  employed: 'employed',
  unemployed: 'unemployed',
}

export const UserProvider = ({
  children,
  user,
  isUserOnboarding,
  isUserContactLess,
  isDisplayUsernameFeatureFlagEnabled,
  isMissingWorkSituation,
  device,
}: PropsWithChildren<UserProviderProps>) => {
  const [userType, setUserType] = useState<UserTypes>(getUserTypeByUrl())
  const [workSituation, setWorkSituation] = useState<string>()
  const [userInformation] = useState<User>(user)
  const [isCustomLogin] = useState<boolean>(getIsCustomLogin())

  return (
    <UserContext.Provider
      value={{
        userType,
        setUserType,
        user: userInformation,
        isUserOnboarding,
        isUserContactLess,
        setWorkSituation,
        workSituation,
        isDisplayUsernameFeatureFlagEnabled,
        isMissingWorkSituation,
        device,
        isCustomLogin,
        isArbetsgivarinfoUser: getIsArbetsgivarinfoUser(),
        linkIdFromUrl: getLinkIdFromUrl(),
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export interface UseUser {
  triggerCancelLogin: () => void
  userType: UserTypes
  changeUserType: (userType: string) => void
  user: User
  isUserOnboarding: boolean
  isUserContactLess: boolean
  setWorkSituation: (workSituation: string) => void
  workSituation: string
  isDisplayUsernameFeatureFlagEnabled: boolean
  isMissingWorkSituation: boolean
  device: IDevice
  isCustomLogin: boolean
  isArbetsgivarinfoUser: boolean
  linkIdFromUrl?: string
  isUserLoggedIn: boolean
}

const useUser = (): UseUser => {
  const {
    isUserOnboarding,
    isUserContactLess,
    userType,
    setUserType,
    user,
    setWorkSituation,
    workSituation,
    isDisplayUsernameFeatureFlagEnabled,
    isMissingWorkSituation,
    device,
    isCustomLogin,
    isArbetsgivarinfoUser,
    linkIdFromUrl,
  } = useContext<UserContextValues>(UserContext)

  const changeUserType = (newUserType: UserTypes) => {
    setUserType(newUserType)
    history.replaceState(
      null,
      '',
      `/login${isCustomLogin ? '/custom' : ''}${
        newUserType === UserTypes.arbetsgivare ? '/arbetsgivare' : ''
      }`
    )
  }

  const isUserLoggedIn =
    Boolean(user?.name) || Boolean(user?.socialSecurityNumber)

  const triggerCancelLogin = useCallback(() => abortLogin(), [])

  return {
    isUserOnboarding,
    isUserContactLess,
    userType,
    changeUserType,
    triggerCancelLogin,
    user,
    setWorkSituation,
    workSituation,
    isDisplayUsernameFeatureFlagEnabled,
    isMissingWorkSituation,
    device,
    isCustomLogin,
    isArbetsgivarinfoUser,
    linkIdFromUrl,
    isUserLoggedIn,
  }
}

export default useUser
