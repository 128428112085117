import React from 'react'
import { GTMTracker } from '@trr/gtm-tracking'
import { IAppShellConfiguration } from 'App.types'
import { User, UserProvider } from 'Utils/Hooks/useUser'
import { PagesProvider } from 'Utils/Hooks/usePages'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'

import App from './App'
import { CompanyProvider } from './Utils/Hooks/useCompany'
import { AuthenticationProvider } from './Utils/Hooks/useAuthentication'

interface LoginFlowAppShellConfiguration extends IAppShellConfiguration {
  user?: User
}

interface UserRoleChecks {
  isUserOnboarding: boolean
  isUserContactLess: boolean
  isMissingWorkSituation: boolean
}

const userRoleChecks = (userRoles: string[]): UserRoleChecks => {
  const roleExists = (explicitRole: string): boolean => {
    return userRoles?.some((role) => role.toLowerCase() === explicitRole)
  }

  return {
    isMissingWorkSituation: roleExists('missingworksituation'),
    isUserContactLess: roleExists('missingemail'),
    isUserOnboarding: roleExists('onboarding'),
  }
}

const Index = ({
  authentication,
  enabledFeatures,
  device,
}: LoginFlowAppShellConfiguration) => {
  const { profile } = authentication

  // If length of sub is above 13 it's definetly not a socialSecurityNumber.
  // 13 if there by any chance is a '-' somewhere in the stored socialSecurityNumber
  const socialSecurityNumber = profile?.sub?.length > 13 ? '' : profile?.sub

  const user: User = {
    name: profile?.name,
    socialSecurityNumber: socialSecurityNumber,
  }

  const isDisplayUsernameFeatureFlagEnabled = enabledFeatures.some(
    (feat) => feat === 'LoginFlow-MicroFrontend_usernamePassword_perma_240507'
  )

  const { isUserOnboarding, isUserContactLess, isMissingWorkSituation } =
    userRoleChecks(authentication.profile.userRoles)

  const theme = createTheme(themeOptions, defaultLocale)

  return (
    <ThemeProvider theme={theme}>
      <AuthenticationProvider authentication={authentication}>
        <CompanyProvider>
          <UserProvider
            device={device}
            user={user}
            isUserOnboarding={isUserOnboarding}
            isUserContactLess={isUserContactLess}
            isDisplayUsernameFeatureFlagEnabled={
              isDisplayUsernameFeatureFlagEnabled
            }
            isMissingWorkSituation={isMissingWorkSituation}
          >
            <PagesProvider
              isUserOnboarding={isUserOnboarding}
              isUserContactLess={isUserContactLess}
              isMissingWorkSituation={isMissingWorkSituation}
            >
              <GTMTracker mfName={'loginflow'}>
                <App />
              </GTMTracker>
            </PagesProvider>
          </UserProvider>
        </CompanyProvider>
      </AuthenticationProvider>
    </ThemeProvider>
  )
}

export default Index
