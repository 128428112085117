import React, { useEffect } from 'react'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { SiteTemplate } from 'Components'
import { pageIdMap } from 'Pages'
import useUser from 'Utils/Hooks/useUser'
import usePages from 'Utils/Hooks/usePages'
import { UserTypes } from 'App.types'

const App = () => {
  const { triggerCancelLogin, userType } = useUser()
  const { currentPageId } = usePages()
  const trackVirtualView = useTrackVirtualView('login_wizard')

  useEffect(() => {
    if (currentPageId !== '') {
      trackVirtualView(currentPageId)
    }
  }, [currentPageId, trackVirtualView])

  const CurrentPage = pageIdMap[currentPageId]
  return (
    <SiteTemplate
      isEmployer={userType === UserTypes.arbetsgivare}
      onCancel={triggerCancelLogin}
    >
      {CurrentPage && <CurrentPage />}
    </SiteTemplate>
  )
}

export default App
