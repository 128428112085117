import { Box, Button, Typography } from '@mui/material'
import { ButtonRow, CreatingAccountLoader, PageTitle } from 'Components'
import BasePage from 'Pages/BasePage'
import useGetArbetsgivarinfo from 'Utils/Hooks/useGetArbetsgivarinfo'
import usePostArbetsgivarinfo from 'Utils/Hooks/usePostArbetsgivarinfo'
import useCompany from 'Utils/Hooks/useCompany'
import useCreateUser from 'Utils/Hooks/useCreateUser'
import useUser, { WorkSituations } from 'Utils/Hooks/useUser'
import React, { useEffect } from 'react'

const ArbetsgivarinfoConfirmationPage = () => {
  const {
    getArbetsgivarinfo,
    arbetsgivarinfo,
    getArbetsgivarinfoIsSuccess,
    getArbetsgivarinfoError,
  } = useGetArbetsgivarinfo()

  const {
    addArbetsgivarinfoIdToUser,
    addArbetsgivarinfoError,
    addArbetsgivarinfoIsSuccess,
  } = usePostArbetsgivarinfo()

  const { triggerCancelLogin, isUserOnboarding } = useUser()

  const { fetchCompany, company, error: fetchCompanyError } = useCompany()

  const {
    createUser,
    isPosting: isPostingUser,
    isSuccess: isCreateUserSuccess,
  } = useCreateUser()

  useEffect(() => {
    getArbetsgivarinfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getArbetsgivarinfoIsSuccess)
      fetchCompany(arbetsgivarinfo.organizationNumber, WorkSituations.employed)
  }, [getArbetsgivarinfoIsSuccess, fetchCompany, arbetsgivarinfo])

  // If user creation OR add arbetsgivarinfo is successful (i.e user has succesfully verified the company)
  // app shell will redirect us after the event is processed, so to avoid glitching UI we simply display the loader
  // and app shell will redirect us after a short delay.
  const shouldDisplayLoaderWhileRedirecting =
    isCreateUserSuccess || addArbetsgivarinfoIsSuccess

  const displayLoader =
    !company ||
    !arbetsgivarinfo ||
    isPostingUser ||
    shouldDisplayLoaderWhileRedirecting

  const displayError =
    getArbetsgivarinfoError || fetchCompanyError || addArbetsgivarinfoError

  const handleCancel = () => triggerCancelLogin()
  const handleContinue = () => {
    if (isUserOnboarding) createUser()
    else addArbetsgivarinfoIdToUser()
  }

  if (displayError)
    return (
      <BasePage>
        <PageTitle sx={{ mb: 8 }}>Något gick fel</PageTitle>
        <Button onClick={handleCancel}>Gå till startsidan</Button>
      </BasePage>
    )

  if (displayLoader)
    return (
      <CreatingAccountLoader testSelector="loader">
        Laddar..
      </CreatingAccountLoader>
    )

  return (
    <BasePage>
      <PageTitle sx={{ mb: 8 }}>Verifiera din arbetsgivare</PageTitle>
      <Box>
        <Typography mb={5}>
          Stämmer det att du arbetar för{' '}
          <Typography component="span" fontWeight="bold">
            {company?.name}
          </Typography>
          ?
        </Typography>
        <ButtonRow>
          <Button onClick={handleContinue} data-testid="continue-button">
            Ja, det stämmer
          </Button>
          <Button onClick={handleCancel} variant="text">
            Nej, det stämmer inte
          </Button>
        </ButtonRow>
      </Box>
    </BasePage>
  )
}

export default ArbetsgivarinfoConfirmationPage
