import React, { useEffect, useState } from 'react'
import usePages from 'Utils/Hooks/usePages'
import useTexts from 'Utils/Hooks/useTexts'
import useCompany from 'Utils/Hooks/useCompany'
import isValidOrgNumber from 'Utils/Helpers/isValidOrgNumber'
import useUser from 'Utils/Hooks/useUser'
import {
  Typography,
  TextField,
  Button,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material'
import { Launch } from '@mui/icons-material'
import { CreatingAccountLoader, PageTitle } from 'Components'

import BasePage from '../BasePage'

import texts from './texts'

const SelectArbetsgivarePage = () => {
  const { goToNextPage } = usePages()
  const { fetchCompany, isLoading, error, isSuccess } = useCompany()
  const [inputFieldText, setInputFieldText] = useState<string>('')
  const [orgFieldError, setOrgFieldError] = useState('')
  const [orgFieldHasError, setOrgFieldHasError] = useState(false)
  const [fetchCompanyHasError, setFetchCompanyHasError] = useState(false)
  const { workSituation } = useUser()

  const pageTexts = useTexts(texts)

  const fetchCompanyAndGotoNextPage = () => () => {
    if (isValidOrgNumber(inputFieldText)) {
      fetchCompany(inputFieldText, workSituation)
    } else {
      setOrgFieldError(pageTexts.orgNumSyntaxError)
      setOrgFieldHasError(true)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputFieldText(event.target.value)
    setOrgFieldError('')
    setOrgFieldHasError(false)
    setFetchCompanyHasError(false)
  }

  useEffect(() => {
    if (error) {
      setFetchCompanyHasError(true)
    }
  }, [error, pageTexts.companyFetchError])

  useEffect(() => {
    if (isSuccess) {
      goToNextPage()
    }
  }, [isSuccess, goToNextPage])

  return (
    <>
      {isLoading ? (
        <CreatingAccountLoader>Laddar..</CreatingAccountLoader>
      ) : (
        <BasePage>
          <PageTitle sx={{ whiteSpace: { md: 'nowrap', lg: 'normal' } }}>
            {pageTexts.header}
          </PageTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              name="organisationsnummer"
              autoComplete="on"
              aria-required
              aria-invalid={orgFieldHasError}
              aria-describedby={
                orgFieldHasError ? 'a11y-error-orgnr' : undefined
              }
              sx={{ marginBottom: 2, width: { xs: '100%', sm: '400px' } }}
              label={pageTexts.labelForInputField}
              inputProps={{ 'data-testid': 'input-field-selector' }}
              onChange={handleInputChange}
              helperText={orgFieldError}
              error={orgFieldHasError}
            />
            <Box
              component="span"
              id="a11y-error-orgnr"
              aria-live={orgFieldHasError ? 'assertive' : 'off'}
              sx={{ display: 'none' }}
            >
              {pageTexts.a11yErrorMessageOrgNrIncorrectFormat}
            </Box>
            <Button
              variant="outlined"
              data-testid={'search-button'}
              onClick={fetchCompanyAndGotoNextPage()}
              sx={{
                width: { xs: '100%', sm: '200px' },
                marginBottom: fetchCompanyHasError ? 1 : { xs: 4, sm: 8 },
              }}
            >
              Sök arbetsgivare
            </Button>
            {fetchCompanyHasError && (
              <Box
                aria-live="assertive"
                component="span"
                sx={{
                  color: 'error.main',
                  marginBottom: { xs: 4, sm: 8 },
                }}
                data-testid="fetch-company-error"
              >
                {pageTexts.companyFetchError}
              </Box>
            )}
          </Box>

          <Typography variant="subtitle1">
            {pageTexts.hjalpAttHittaOrgnummer}
          </Typography>
          <ListItem color="inherit" sx={{ padding: 0 }} divider={false}>
            <ListItemButton
              href="https://www.allabolag.se"
              target="_blank"
              sx={{ padding: 0, flexFlow: 'nowrap' }}
            >
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Launch />
              </ListItemIcon>
              <ListItemText
                primary={pageTexts.websiteLink}
                secondary={pageTexts.websiteInfo}
              />
            </ListItemButton>
          </ListItem>
        </BasePage>
      )}
    </>
  )
}

export default SelectArbetsgivarePage
