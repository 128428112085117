import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { getNextPageId, getPrevPageId, pageIds } from 'Pages'
import useLoginFlowUser from 'Utils/Hooks/useLoginFlowUser'
import { UserTypes } from 'App.types'

export interface PagesContextValues {
  currentPageId: string
  setCurrentPageId: (pageId: string) => void
  isTermsAndConditionsEnabled: boolean
}

export interface UsePagesProps {
  isUserOnboarding?: boolean
  isUserContactLess?: boolean
  isMissingWorkSituation?: boolean
}

export const PagesContext = createContext<PagesContextValues>(undefined)

export const PagesProvider = ({
  children,
  isUserOnboarding = false,
  isUserContactLess = false,
  isMissingWorkSituation = false,
}: PropsWithChildren<UsePagesProps>) => {
  const [currentPageId, setCurrentPageId] = useState<string | undefined>(
    undefined
  )

  const { userType, isArbetsgivarinfoUser, isUserLoggedIn } = useLoginFlowUser()
  // Temporary solution to hide links and ToC until we receive the
  // correct URLs from the teams in charge of creating said pages
  // Should be removed once all links are updated
  const [isTermsAndConditionsEnabled] = useState(false)

  const showTjanstemanPage =
    isUserOnboarding && userType === UserTypes.tjansteman

  const showCurrentArbetsSituation =
    isUserOnboarding && userType === UserTypes.tjansteman

  const showArbetsgivarinfoConfirmationPage =
    (isUserOnboarding && isArbetsgivarinfoUser) ||
    (isUserLoggedIn && isArbetsgivarinfoUser)

  useEffect(() => {
    const pageId: string =
      (showArbetsgivarinfoConfirmationPage &&
        pageIds.arbetsgivarinfoConfirmationPage) ||
      (showTjanstemanPage && pageIds.selectTjanstemanPage) ||
      (showCurrentArbetsSituation && pageIds.currentArbetssituation) ||
      (isUserOnboarding && pageIds.selectArbetsgivare) ||
      (isMissingWorkSituation && pageIds.currentArbetssituation) ||
      (isUserContactLess && pageIds.contactInformation) ||
      pageIds.login
    setCurrentPageId(pageId)
  }, [
    isUserOnboarding,
    isUserContactLess,
    isMissingWorkSituation,
    showTjanstemanPage,
    showCurrentArbetsSituation,
    showArbetsgivarinfoConfirmationPage,
  ])

  return (
    <PagesContext.Provider
      value={{
        currentPageId,
        setCurrentPageId,
        isTermsAndConditionsEnabled,
      }}
    >
      {children}
    </PagesContext.Provider>
  )
}

interface UsePages {
  currentPageId: string
  goToNextPage: () => void
  goToPrevPage: () => void
  isTermsAndConditionsEnabled: boolean
}

const usePages = (): UsePages => {
  const { currentPageId, setCurrentPageId, isTermsAndConditionsEnabled } =
    useContext(PagesContext)
  const { userType } = useLoginFlowUser()

  const goToNextPage = () => {
    setCurrentPageId(getNextPageId(userType, currentPageId))
  }

  const goToPrevPage = () => {
    setCurrentPageId(getPrevPageId(userType, currentPageId))
  }

  return {
    currentPageId,
    goToNextPage,
    goToPrevPage,
    isTermsAndConditionsEnabled,
  }
}

export default usePages
