interface IConfiguration {
  PUBLIC_WEB_API_URL: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
  PUBLIC_WEB_API_URL: process.env.PUBLIC_WEB_API_URL,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
  REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration => ({
  ...compiletimeConfiguration,
  ...APP_CONFIGURATION,
})

export default getConfig
