import React from 'react'
import { ListItemText, Box, SxProps, Theme, Divider } from '@mui/material'

import { Company } from '../../Utils/Hooks/useCompany'
import { User } from '../../Utils/Hooks/useUser'

interface NameAndNumberProps {
  name: string
  number: string
}

export interface PersonalInformationProps {
  company?: Company
  user: User
}

const nameAndPersonalNumberSx: SxProps<Theme> = {
  '&:first-child': {
    paddingRight: 3,
  },
  '&:last-child': {
    paddingLeft: { sm: 3 },
  },
  '&:only-child': {
    padding: 0,
  },
}

const NameAndNumber = ({ name, number }: NameAndNumberProps) => (
  <Box sx={nameAndPersonalNumberSx}>
    <ListItemText
      primary={name ?? ''}
      secondary={number ?? ''}
      sx={{ padding: 0 }}
    />
  </Box>
)

const personalInformationBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  maxWidth: '600px',
  paddingBottom: 4,
}

const PersonalInformation = ({
  company = undefined,
  user,
}: PersonalInformationProps) => {
  return (
    <Box sx={personalInformationBoxSx}>
      <NameAndNumber name={user.name} number={user.socialSecurityNumber} />
      {company && (
        <>
          <Divider
            orientation="vertical"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              backgroundColor: 'primary.light',
            }}
          />
          <NameAndNumber name={company?.name} number={company?.orgNumber} />
        </>
      )}
    </Box>
  )
}

export default PersonalInformation
