export interface Authentication {
  idToken: string
  profile: AuthenticationProfile
}

export interface AuthenticationProfile {
  name: string
  sub: string
  userRoles: string[]
  fullName: string
  idp: string
}

export interface IAppShellConfiguration {
  application: { isUserOnboarding: boolean }
  currentKey: string
  url: string
  idToken: string
  device: IDevice
  enabledFeatures: string[]
  content: unknown
  authentication: Authentication
}

export interface IDevice {
  isMobile: boolean
  isTablet?: boolean
  isDesktop?: boolean
  isXL?: boolean
}

export interface ArbetsgivarinfoApiResponse {
  id: string
  organizationNumber: string
}

export enum UserTypes {
  arbetsgivare = 'arbetsgivare',
  tjansteman = 'tjansteman',
}
