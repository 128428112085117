import { GTMTracker } from '@trr/gtm-tracking'
import { UserProvider } from 'Utils/Hooks/useLoginFlowUser'
import { PagesProvider } from 'Utils/Hooks/usePages'
import {
  useAuthentication,
  useDevice,
  useIsFeatureEnabled,
} from '@trr/app-shell-data'
import { jwtDecode } from 'jwt-decode'
import App from 'App'

interface UserRoleChecks {
  isUserOnboarding: boolean
  isUserContactLess: boolean
  isMissingWorkSituation: boolean
}

const userRoleChecks = (userRoles: string[]): UserRoleChecks => {
  const roleExists = (explicitRole: string): boolean => {
    return userRoles?.some((role) => role.toLowerCase() === explicitRole)
  }

  return {
    isMissingWorkSituation: roleExists('missingworksituation'),
    isUserContactLess: roleExists('missingemail'),
    isUserOnboarding: roleExists('onboarding'),
  }
}

const ContextInitiator = () => {
  const { idToken, userRoles } = useAuthentication()
  const decodedToken =
    idToken && jwtDecode<{ name: string; sub: string }>(idToken)
  const enabledUserNamePassword = useIsFeatureEnabled(
    'LoginFlow-MicroFrontend_usernamePassword_perma_240507'
  )
  const device = useDevice()

  // If length of sub is above 13 it's definetly not a socialSecurityNumber.
  // 13 if there by any chance is a '-' somewhere in the stored socialSecurityNumber
  const socialSecurityNumber =
    decodedToken?.sub?.length > 13 ? '' : decodedToken?.sub

  const user = {
    name: decodedToken?.name,
    socialSecurityNumber: socialSecurityNumber,
  }

  const { isUserOnboarding, isUserContactLess, isMissingWorkSituation } =
    userRoleChecks(userRoles)

  return (
    <UserProvider
      device={device}
      user={user}
      isUserOnboarding={isUserOnboarding}
      isUserContactLess={isUserContactLess}
      isDisplayUsernameFeatureFlagEnabled={enabledUserNamePassword}
      isMissingWorkSituation={isMissingWorkSituation}
    >
      <PagesProvider
        isUserOnboarding={isUserOnboarding}
        isUserContactLess={isUserContactLess}
        isMissingWorkSituation={isMissingWorkSituation}
      >
        <GTMTracker mfName={'loginflow'}>
          <App />
        </GTMTracker>
      </PagesProvider>
    </UserProvider>
  )
}

export default ContextInitiator
