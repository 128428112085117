import { useEffect } from 'react'
import { userCreationFinished } from '@trr/app-shell-communication'

import useFetch from '../useFetch'
import useLoginFlowUser from '../useLoginFlowUser'
import { getAppConfig } from '@trr/app-shell-data'

export interface UsePostArbetsgivarinfo {
  addArbetsgivarinfoIdToUser: () => void
  addArbetsgivarinfoIsSuccess: boolean
  addArbetsgivarinfoIsLoading: boolean
  addArbetsgivarinfoError: Error
}

const usePostArbetsgivarinfo = (): UsePostArbetsgivarinfo => {
  const {
    isSuccess: addArbetsgivarinfoIsSuccess,
    isLoading: addArbetsgivarinfoIsLoading,
    error: addArbetsgivarinfoError,
    performFetch,
  } = useFetch()
  const { linkIdFromUrl } = useLoginFlowUser()

  const addArbetsgivarinfoIdToUser = (): void => {
    performFetch(
      `${getAppConfig().COMMON.PUBLIC_WEB_API_URL}/user/arbetsgivarinfo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: linkIdFromUrl,
        }),
      }
    )
  }

  useEffect(() => {
    if (addArbetsgivarinfoIsSuccess) {
      // this just signals to appshell that we are done and need a redirect. It has nothing to do with finishing creation of user.
      userCreationFinished(true)
    }
  }, [addArbetsgivarinfoIsSuccess])

  return {
    addArbetsgivarinfoIsSuccess,
    addArbetsgivarinfoIsLoading,
    addArbetsgivarinfoError,
    addArbetsgivarinfoIdToUser,
  }
}

export default usePostArbetsgivarinfo
