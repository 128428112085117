import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'

const {
  COMMON: { PUBLIC_WEB_API_URL },
} = getAppConfig()

const extendedFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: PUBLIC_WEB_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: extendedFetchBaseQuery(),
  endpoints: () => ({}),
})

export default baseApi
