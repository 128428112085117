import { userCreationFinished } from '@trr/app-shell-communication'
import { useEffect, useState } from 'react'
import { isCookieEnabled } from 'Utils/Helpers/cookies'
import useLoginFlowUser, { WorkSituations } from '../useLoginFlowUser'
import { useGetCompanyQueryState } from 'State/Api/Company'
import { useSliceStateSelector } from 'State/Slices'
import { useCreateUserMutation } from 'State/Api/User'
import { type SerializedError } from '@reduxjs/toolkit'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query'

export interface PostGatheredInformationToBe {
  createUser: () => void
  isSuccess: boolean
  isPosting: boolean
  error: FetchBaseQueryError | SerializedError
}

interface PostUser {
  companyId?: string
  orgNumber?: string
  workSituation?: string
  workRole?: string[]
  arbetsgivarinfoId?: string
}

const useCreateUser = (): PostGatheredInformationToBe => {
  const { organizationNumber, workSituation } = useSliceStateSelector(
    (state) => state.slices.inputs
  )

  const { data: company } = useGetCompanyQueryState({
    organizationNumber,
    workSituation,
  })

  const [triggerCreateUser, createUserData] = useCreateUserMutation()
  const [isUserCreationFinished, setIsUserCreationFinished] = useState(false)

  const {
    isUserContactLess,
    isMissingWorkSituation,
    linkIdFromUrl,
    isArbetsgivarinfoUser,
  } = useLoginFlowUser()

  useEffect(() => {
    const leaveLoginFlow =
      (!isUserContactLess && isMissingWorkSituation) || isArbetsgivarinfoUser
    const isTestUser = isCookieEnabled('force-redirect-test-user')
    // When running Cypress tests we need a way to force-leave the loginflow due to
    // A generated user profile from the backend setting us in a dead state otherwise
    // This is handled through a cookie that is only used here.
    if (createUserData?.isSuccess && isTestUser && !isUserCreationFinished) {
      userCreationFinished(true)
      setIsUserCreationFinished(true)
    } else if (createUserData?.isSuccess && !isUserCreationFinished) {
      userCreationFinished(leaveLoginFlow)
      setIsUserCreationFinished(true)
    }
  }, [
    createUserData?.isSuccess,
    isUserContactLess,
    isMissingWorkSituation,
    isUserCreationFinished,
    isArbetsgivarinfoUser,
  ])

  const createUser = (): void => {
    let postUser: PostUser = {}

    // We don't send company and role if none exist
    if (company) {
      postUser = {
        companyId: company.id,
        orgNumber: company.organizationNumber,
        workRole: [],
      }
    }

    if (workSituation) {
      postUser.workSituation = workSituation
    }

    if (linkIdFromUrl) {
      postUser.arbetsgivarinfoId = linkIdFromUrl
      postUser.workSituation = WorkSituations.employed
    }
    void triggerCreateUser(postUser)
  }
  return {
    createUser,
    isPosting: createUserData?.isLoading,
    isSuccess: createUserData?.isSuccess,
    error: createUserData?.error,
  }
}

export default useCreateUser
