import { Box, RadioGroup } from '@mui/material'

interface RadioButtonGroupProps {
  displayRow: boolean
  marginBottom?: number
  children: JSX.Element[]
  isHidden?: boolean
}

const RadioButtonGroup = ({
  displayRow,
  children,
  marginBottom = 6,
  isHidden = false,
}: RadioButtonGroupProps) => {
  if (isHidden) return null

  return (
    <Box mb={marginBottom}>
      <RadioGroup row={displayRow}>{children}</RadioGroup>
    </Box>
  )
}

export default RadioButtonGroup
