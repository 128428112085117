import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

interface ListItemProps {
  icon: JSX.Element
  label: string
  testId: string
  hidden?: boolean
  onClick: () => void
}

const ListItem = ({ icon, label, testId, hidden, onClick }: ListItemProps) => {
  if (hidden) return

  return (
    <ListItemButton
      data-testid={testId}
      onClick={onClick}
      divider
      sx={{ height: '80px' }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

export default ListItem
