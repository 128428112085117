import { PropsWithChildren } from 'react'
import useLoginFlowUser from 'Utils/Hooks/useLoginFlowUser'
import PersonalInformation from 'Components/PersonalInformation'
import { Box, type SxProps, type Theme } from '@mui/material'

interface BasePageProps {
  testId?: string
  loggedIn?: boolean
  showUserInformation?: boolean
}

const BasePage = ({
  testId,
  showUserInformation = true,
  children,
}: PropsWithChildren<BasePageProps>) => {
  const { isUserOnboarding, isUserContactLess, user } = useLoginFlowUser()

  const shouldShowUserInformation =
    showUserInformation && (isUserOnboarding || isUserContactLess)

  type StyleNames = 'basePage' | 'content'
  const basePageStyles: Record<StyleNames, SxProps<Theme>> = {
    basePage: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      marginBottom: 9,
    },
  }

  return (
    <Box sx={basePageStyles.basePage} data-testid={testId}>
      {shouldShowUserInformation && <PersonalInformation user={user} />}
      <Box sx={basePageStyles.content}>{children}</Box>
    </Box>
  )
}

export default BasePage
