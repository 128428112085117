const isValidOrgNumberFormat = (orgNr: string) => {
  const regex = new RegExp('^[0-9]{6}[-]?[0-9]{4}$')
  return regex.test(orgNr)
}
const isValidOrgNumberChecksum = (orgNr: string) => {
  const digits = orgNr.substring(0, orgNr.length - 1).replace(/[^0-9]/g, '')
  let sum = 0

  digits.split('').forEach((digit, index) => {
    const val = parseInt(digit) * (index % 2 === 0 ? 2 : 1)
    val
      .toString()
      .split('')
      .forEach((digit2) => {
        sum += parseInt(digit2)
      })
  })

  const checksum = (Math.ceil(sum / 10) * 10 - sum) % 10

  const lastDigit = orgNr[orgNr.length - 1]

  return checksum.toString() === lastDigit
}

const isValidOrgNumber = (orgNr: string): boolean => {
  return isValidOrgNumberFormat(orgNr) && isValidOrgNumberChecksum(orgNr)
}

export default isValidOrgNumber
