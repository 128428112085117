import {
  ListItemText,
  Box,
  type SxProps,
  type Theme,
  Divider,
} from '@mui/material'

import { User } from '../../Utils/Hooks/useLoginFlowUser'
import { useSliceStateSelector } from 'State/Slices'
import { useGetCompanyQueryState } from 'State/Api/Company'
import { pageIds } from 'Pages'
import usePages from 'Utils/Hooks/usePages'

interface NameAndNumberProps {
  name: string
  number: string
}

export interface PersonalInformationProps {
  user: User
}

const nameAndPersonalNumberSx: SxProps<Theme> = {
  '&:first-child': {
    paddingRight: 3,
  },
  '&:last-child': {
    paddingLeft: { sm: 3 },
  },
  '&:only-child': {
    padding: 0,
  },
}

const NameAndNumber = ({ name, number }: NameAndNumberProps) => (
  <Box sx={nameAndPersonalNumberSx}>
    <ListItemText
      primary={name ?? ''}
      secondary={number ?? ''}
      sx={{ padding: 0 }}
    />
  </Box>
)

const personalInformationBoxSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  maxWidth: '600px',
  paddingBottom: 4,
}

const PersonalInformation = ({ user }: PersonalInformationProps) => {
  const { organizationNumber, workSituation } = useSliceStateSelector(
    (state) => state.slices.inputs
  )

  const { currentPageId } = usePages()
  const showCompanyForPages = [
    pageIds.verifyArbetsgivare,
    pageIds.currentArbetssituation,
    pageIds.arbetsgivareSelected,
    pageIds.contactInformation,
    pageIds.arbetsgivarinfoConfirmationPage,
  ]
  const showCompany = showCompanyForPages.includes(currentPageId)

  const { data: company } = useGetCompanyQueryState({
    organizationNumber,
    workSituation,
  })

  return (
    <Box sx={personalInformationBoxSx}>
      <NameAndNumber name={user.name} number={user.socialSecurityNumber} />
      {showCompany && company && (
        <>
          <Divider
            orientation="vertical"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              backgroundColor: 'primary.light',
            }}
          />
          <NameAndNumber
            name={company?.name}
            number={company?.organizationNumber}
          />
        </>
      )}
    </Box>
  )
}

export default PersonalInformation
